import './App.css';
import Brands from './component/Brands';
import FandQ from './component/FandQ';
import Footer from './component/Footer';
import Header from './component/Header';
import Hero from './component/Hero';
import Services from './component/Services';

function App() {
  return (
   <>
   <Header/>
   <Hero/>
   <Services/>
   <Brands/>
   <FandQ/>
   <Footer/>
   
   </>
  );
}

export default App;
