import React from 'react'
import '../css/contact.css'
export default function Footer() {
    return (
        <>
            {/* <section style={{display: 'flex', justifyContent: 'center'}}>
                <div className="footer_sec">
                    <div>
                        <div style={{textAlign: 'center'}}>
                            <p>Follow us:</p>
                        </div>
                        <div style={{display: 'flex', marginTop: '10px'}}>
                            <div><a href>
                                <img className="cont_logo"
                                    src="https://amandgupta.vercel.app/img/4.png"
                                    alt/></a></div>
                            <div><a href>
                                <img className="cont_logo"
                                    src="https://amandgupta.vercel.app/img/1.png"
                                    alt/></a></div>
                            <div><a href>
                                <img className="cont_logo"
                                    src="https://amandgupta.vercel.app/img/3.png"
                                    alt/></a></div>

                        </div>
                    </div>
                    <div className="cont_detials"
                        style={{display: 'flex', marginTop:'20px'}}>
                        <div>
                            <span className="material-symbols-outlined">
                                phone_in_talk
                            </span>
                            <p><a href>+91 XXXXXXXX23</a></p>
                        </div>
                        <div>
                            <span className="material-symbols-outlined">
                                mail
                            </span>
                            <p><a href>email@example.com</a></p>

                        </div>
                        <div>
                            <span className="material-symbols-outlined">
                                pin_drop
                            </span>

                            <p><a href>123 Main Street Anytown, USA, 2141</a></p>
                        </div>
                    </div>
                </div>

            </section> */}
            {/* <section>
                <div className='footer_bottom'></div>
            </section> */}

            <br />
            <br />
            <br />
            <br />
            <br />
            <div>
                <hr />
                <br />
                <div style={{ textAlign: 'center' }}>
                    © 2024 Paras Solutions™. All Rights Reserved.
                </div>
                <br />
            
            </div>
        </>
    )
}
