import React from 'react'
import '../css/services.css'

export default function Services() {
  return (
    <>
    <section>
            <div className="ser_head">
                <div className="ser_head_t">Services</div>
                <div style={{fontSize: '31px'}}>What we are offering</div>
            </div>

            <div className="ser_contant">
                <div className="ser_card">
                    <div>
                        <span
                            className="material-symbols-outlined ser_card_icon">travel_explore</span>
                    </div>
                    <div style={{marginTop: '15px'}}>
                        <h3>SEO</h3>
                    </div>
                    <div>
                        <p>We optimize your site's structure, content, and
                            keywords to rank higher on search engine results
                            pages, increasing your chances of being discovered
                            by potential customers.</p>
                    </div>
                    <div style={{marginTop: '85px'}}>
                        <span>KNOW MORE</span>
                    </div>
                </div>
                <div className="ser_card">
                    <div>
                        <span
                            className="material-symbols-outlined ser_card_icon">person</span>
                    </div>
                    <div style={{marginTop: '15px'}}>
                        <h3>Content Marketing</h3>
                    </div>
                    <div>
                        <p>
                            Our content marketing services include creating
                            informative blog posts, engaging videos, and
                            shareable infographics to captivate your audience
                            and drive traffic to your website.</p>
                    </div>
                    <div style={{marginTop: '85px'}}>
                        <span>KNOW MORE</span>
                    </div>
                </div>
                <div className="ser_card">
                    <div>
                        <span
                            className="material-symbols-outlined ser_card_icon">computer</span>
                    </div>
                    <div style={{marginTop: '15px'}}>
                        <h3>Website Design</h3>
                    </div>
                    <div>
                        <p>
                            Our design experts craft visually stunning and
                            user-friendly websites optimized for performance and
                            conversion, ensuring a seamless online experience
                            for your visitors.</p>
                    </div>
                    <div style={{marginTop: '85px'}}>
                        <span>KNOW MORE</span>
                    </div>
                </div>
                <div className="ser_card">
                    <div>
                        <span
                            className="material-symbols-outlined ser_card_icon">ads_click</span>
                    </div>
                    <div style={{marginTop: '15px'}}>
                        <h3>Social Media Marketing</h3>
                    </div>
                    <div>
                        <p>Social Media Marketing
                            Engage and expand your audience across social media
                            platforms with our tailored marketing strategies.
                            From content creation to ad campaigns, we help you
                            connect with your target demographic</p>
                    </div>
                    <div style={{marginTop: '85px'}}>
                        <span>KNOW MORE</span>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
