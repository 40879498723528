import React from 'react';
import '../css/hero.css';

export default function Hero() {

    const spans = [
        11, 12, 11, 14, 22, 27, 14, 16, 19, 23,
        24, 22, 21, 26, 25, 19, 11, 12, 11, 13,
        12, 11, 15, 14
    ];
    return (
        <>

            <section>
                <div className="bubbles_container">
                    <div className="bubbles">
                        {spans.map((value, index) => (
                            <span key={index} style={{ '--i': value }}></span>
                        ))}
                    </div>
                </div>

            </section>

            <section style={{ marginTop: '-700px', position: 'relative' }}>
                <div className="hero_sec">
                    <div className="hero_qout">
                        <span> The Best Tech Solutions Agency.</span>
                    </div>
                    <div className="hero_para">
                        <span>We believe in combining innovative design, sustainable
                            practices, and exceptional craftsmanship to bring your
                            vision to life.</span>
                    </div>
                    <div>
                        <button className="button-85">Let's Talk</button>
                    </div>
                </div>
            </section>

            <section>
                <div style={{ textAlign: 'center' }}>
                    <div className="team_sec">

                        <div className="team_scroll">
                            <h1>The Best Tech Solutions</h1>
                            <h1>The Best Tech Solutions</h1>
                            <h1>The Best Tech Solutions</h1>
                            <h1>The Best Tech Solutions</h1>
                            <h1>The Best Tech Solutions</h1>
                            <h1>The Best Tech Solutions</h1>
                            <h1>The Best Tech Solutions</h1>
                            <h1>The Best Tech Solutions</h1>
                            <h1>The Best Tech Solutions</h1>
                            <h1>The Best Tech Solutions</h1>
                            <h1>The Best Tech Solutions</h1>
                            <h1>The Best Tech Solutions</h1>
                            <h1>The Best Tech Solutions</h1>
                        </div>
                        <img className='team_image'
                            src="https://framerusercontent.com/images/tVDg230sxN3guk1uVhxlFkIu1s.png"
                            alt ="Paras Print Team"/>

                    </div>
                    <div style={{ display: 'none' }}>
                        <div className="team_bage">
                            <div>
                                We are Provides Tech Solutions

                            </div>
                            <div style={{ width: '100%', height: '0.1px', background: '#5a5a5a45', margin: '10px 0px' }}></div>
                            <div>
                                We are Provides Tech Solutions

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
