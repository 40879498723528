import React from 'react';
import '../css/fandq.css';

export default function FandQ() {

    const showAns = (id) => {
        document.getElementById(id)?.classList.toggle("disnone");
        console.log('asdkjf');
    };


  return (
    <>
     <section style={{marginTop: '90px'}}>
            <div className="ser_head">
                <div className="ser_head_t">FREQUENTLY ASKED QUESTIONS</div>
                <div style={{fontSize: '31px'}}>Got Questions?</div>
                <div style={{fontSize: '31px'}}>We've Got Answers!</div>
            </div>

        </section>

        <section className='More_sectionss'>

            <div className='more_faq_cont'>
                <div onClick={()=>showAns('more_1')} className="qaa_drop">
                    <h3>1. What is SEO and why is it important?</h3>
                    <span className="material-symbols-outlined">expand_more</span>
                </div>
                <div id='more_1' className='disnone'>
                    <p>SEO, or Search Engine Optimization, is the process of
                        optimizing a website to improve its visibility and
                        ranking on search engine results pages (SERPs). It's
                        important because it helps businesses attract organic
                        traffic, increase their online visibility, and
                        ultimately drive more leads and conversions.</p>
                </div>
            </div>

            <div className='more_faq_cont'>
                <div onClick={()=>showAns('more_2')}className="qaa_drop"> <h3>2.
                        How long does it take to see results from SEO?</h3>
                    <span className="material-symbols-outlined">expand_more</span>
                </div>
                <div id='more_2' className='disnone'>
                    <p>SEO is a long-term strategy, and the timeline for seeing
                        results can vary depending on factors such as the
                        competitiveness of the industry, the current state of
                        the website, and the effectiveness of the SEO efforts.
                        Generally, noticeable improvements can be seen within a
                        few months, but significant results may take six months
                        to a year or more.</p>
                </div>
            </div>

            <div className='more_faq_cont'>
                <div onClick={()=>showAns('more_3')}className="qaa_drop"> <h3>3.
                        What are the key factors that influence SEO
                        rankings?</h3>
                    <span className="material-symbols-outlined">expand_more</span>
                </div>
                <div id='more_3' className='disnone'>
                    <p>While some basic SEO tasks can be done by individuals
                        with a good understanding of SEO principles, achieving
                        significant results often requires expertise,
                        experience, and access to specialized tools and
                        resources. Hiring an experienced SEO agency can help
                        ensure that your SEO efforts are strategic, effective,
                        and aligned with your business goals.</p>
                </div>
            </div>

            <div className='more_faq_cont'>
                <div onClick={()=>showAns('more_4')}className="qaa_drop"> <h3>4.
                        How much does SEO cost?</h3>
                    <span className="material-symbols-outlined">expand_more</span>
                </div>
                <div id='more_4' className='disnone'>
                    <p>The cost of SEO services can vary depending on factors
                        such as the scope of work, the competitiveness of the
                        industry, and the experience level of the SEO agency.
                        Some agencies offer fixed-price packages, while others
                        may charge on a monthly retainer basis. It's important
                        to discuss pricing and expectations with potential SEO
                        providers to find a solution that fits your budget and
                        needs.</p>
                </div>
            </div>

            <div className='more_faq_cont'>
                <div onClick={()=>showAns('more_5')}className="qaa_drop"> <h3>5.
                        How can companies improve their outreach?</h3>
                    <span className="material-symbols-outlined">expand_more</span>
                </div>
                <div id='more_5' className='disnone'>
                    <p>Maintain an updated profile with a comprehensive bio,
                        budget information, and clear expectations to enable
                        gamers to learn more about your company and reach
                        out.</p>
                </div>
            </div>

            <div className='more_faq_cont'>
                <div onClick={()=>showAns('more_6')}className="qaa_drop"> <h3>6.
                        Can I rank #1 on Google for any keyword?</h3>
                    <span className="material-symbols-outlined">expand_more</span>
                </div>
                <div id='more_6' className='disnone'>
                    <p>Ranking #1 on Google for highly competitive keywords can
                        be challenging and may require significant time,
                        resources, and ongoing optimization efforts. However,
                        focusing on long-tail keywords (more specific, less
                        competitive phrases) and niche topics can still yield
                        valuable organic traffic and visibility
                        improvements.</p>
                </div>
            </div>

            <div className='more_faq_cont'>
                <div onClick={()=>showAns('more_7')}className="qaa_drop"> <h3>7.
                        Is SEO a one-time effort, or does it require ongoing
                        maintenance?</h3>
                    <span className="material-symbols-outlined">expand_more</span>
                </div>
                <div id='more_7' className='disnone'>
                    <p>SEO is an ongoing process that requires continuous
                        monitoring, optimization, and adaptation to changes in
                        search engine algorithms, industry trends, and
                        competitor strategies. Regular updates to content,
                        technical improvements, and link-building efforts are
                        essential for maintaining and improving SEO rankings
                        over time.</p>
                </div>
            </div>

        </section>
    </>
  )
}
