import React, { useEffect } from 'react';
import '../css/brands.css';

export default function Brands() {

    useEffect(() => {
        const scrollers = document.querySelectorAll(".scroller");
    
        // If a user hasn't opted in for reduced motion, then we add the animation
        if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
          addAnimation();
        }
    
        function addAnimation() {
          scrollers.forEach((scroller) => {
            // add data-animated="true" to every `.scroller` on the page
            scroller.setAttribute("data-animated", true);
    
            // Make an array from the elements within `.scroller-inner`
            const scrollerInner = scroller.querySelector(".scroller__inner");
            const scrollerContent = Array.from(scrollerInner.children);
    
            // For each item in the array, clone it
            // add aria-hidden to it
            // add it into the `.scroller-inner`
            scrollerContent.forEach((item) => {
              const duplicatedItem = item.cloneNode(true);
              duplicatedItem.setAttribute("aria-hidden", true);
              scrollerInner.appendChild(duplicatedItem);
            });
          });
        }
    
        // Cleanup function to remove duplicated elements if necessary
        return () => {
          scrollers.forEach((scroller) => {
            const scrollerInner = scroller.querySelector(".scroller__inner");
            const duplicates = scrollerInner.querySelectorAll('[aria-hidden="true"]');
            duplicates.forEach((duplicate) => {
              scrollerInner.removeChild(duplicate);
            });
          });
        };
      }, []);
    
 






  return (
    <>
       <section style={{marginTop: '90px'}}>
            <div className="ser_head">
                <div className="ser_head_t">BRANDS COLLABORAATIONS</div>
                <div style={{fontSize: '31px'}}>Brands that trust us</div>
            </div>

            <div className='scroller_contaienr'>
                <div>
                    <div className="scroller" data-speed="fast">
                        <ul className="tag-list scroller__inner">
                            <li>PIRAMAL</li>
                            <li>IFL</li>
                            <li>ADARSH LAW FORM</li>
                            <li>LISTMONK</li>
                            <li>PARAS PRINT</li>
                            <li>PIRAMAL</li>
                            <li>IFL</li>
                            <li>ADARSH LAW FORM</li>
                            <li>LISTMONK</li>
                            <li>PARAS PRINT</li>
                        </ul>
                    </div>
                 
                </div>
            </div>
        </section>

        <section style={{marginTop: '-90px'}}>

            <div className='brand_container'>

                <div className="brand_sec">

                    <div className="brand_bolbe">
                        <div className="brand_item" style={{marginTop: '20px'}}>
                            <div>15+</div>
                            <div>Years of Experience</div>
                        </div>
                        <div className="brand_item" style={{marginTop: '-9px'}}>
                            <div>200+</div>
                            <div>Successful Projects</div>
                        </div>
                        <div className="brand_item" style={{marginTop: '20px'}}>
                            <div>150+</div>
                            <div>Happy Clients</div>
                        </div>
                        <div className="brand_item" style={{marginTop: '-5px;'}}>
                            <div>160+</div>
                            <div>5 Star Reviews</div>
                        </div>
                    </div>

                </div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div className="brand_per_card">
                        <div> <span className="material-symbols-outlined">
                                language
                            </span></div>
                        <div>
                            <h3>Proven Track Record</h3>
                        </div>
                        <div>
                            <p>We have built a reputation as a trusted and
                                reliable partner in achieving business
                                success.</p>
                        </div>
                    </div>
                    <div className="brand_per_card">
                        <div> <span className="material-symbols-outlined">
                                share
                            </span></div>
                        <div>
                            <h3>Tailored Solutions</h3>
                        </div>
                        <div>
                            <p>We offer personalized solutions tailored to your
                                specific goals, audience, and industry.</p>
                        </div>
                    </div>
                    <div className="brand_per_card">
                        <div> <span className="material-symbols-outlined">
                                document_scanner
                            </span></div>
                        <div>
                            <h3>Client-Centric Focus</h3>
                        </div>
                        <div>
                            <p>Your success is our priority. We prioritize
                                understanding your business goals.</p>
                        </div>
                    </div>
                </div>

                <div className="barnd_card">
                    <div>
                        {/* <span className="material-symbols-outlined">
                            language
                        </span> */}
                        <span>
                            Continuous Innovation
                        </span>

                    </div>
                    <div>
                        {/* <span className="material-symbols-outlined">
                            language
                        </span> */}
                        <span>
                            Dedicated Support
                        </span>
                    </div>
                    <div>
                        {/* <span className="material-symbols-outlined">
                            language
                        </span> */}
                        <span>
                            Positive Client Experiences
                        </span>
                    </div>
                    <div>
                        {/* <span className="material-symbols-outlined">
                            language
                        </span> */}
                        <span>
                            Commitment to Excellence
                        </span>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
