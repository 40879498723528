import React from 'react';
import logo from '../assets/logo.png';
import '../css/header.css';

export default function Header() {
    return (
        <>
            <section>
                <div className="header">
                    <div style={{ width: '200px' }}>
                        <img style={{ width: '150px' }} src={logo} alt="paras solutions logo" />
                    </div>
                    <div className="header_links">
                        <div className="h_link_active"><a href="/">Home</a></div>
                        <div className="h_link"><a href="/">Team</a></div>
                        <div className="h_link"><a href="/">Services</a></div>
                        <div className="h_link"><a href="/">Project</a></div>
                        <div className="h_link"><a href="/">Contact</a></div>
                    </div>
                    <div style={{ width: '200px', display: 'flex', justifyContent: 'flex-end' }}>
                        <button className="button-85">Let's Talk</button>
                    </div>
                </div>
            </section>
        </>
    );
}
